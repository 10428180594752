<template>
  <div class="contents register funding">
    <div class="title flexB">
      <h2>설문 등록</h2>
    </div>
    <div class="box one">
      <div class="flexL">
        <p class="bold">
          카테고리
          <span>*</span>
        </p>
        <select v-model="category">
          <option value="" disabled>선택</option>
          <option value="SPECIAL">스페셜</option>
          <option value="TECH">테크/가전</option>
          <option value="FASHION">패션/잡화</option>
          <option value="BEAUTY">뷰티</option>
          <option value="FOOD">푸드</option>
          <option value="HOMELIVING">홈리빙</option>
          <option value="TRIP">여행/레저</option>
          <option value="SPORTS">스포츠/모빌리티</option>
          <option value="CULTURE">공연/컬쳐</option>
          <option value="KIDS">베이비/키즈</option>
        </select>
      </div>
      <div class="flexL">
        <p class="bold">
          제품명
          <span>*</span>
        </p>
        <input type="text" v-model="productName" />
      </div>
      <div class="flexL">
        <p class="bold">
          제목
          <span>*</span>
        </p>
        <input type="text" v-model="title" />
      </div>
      <div class="flexL">
        <p class="bold">
          설문주최
          <span>*</span>
        </p>
        <input type="text" v-model="provider" />
      </div>
      <!-- <div class="flexL">
        <p class="bold">
          설문목표금액
          <span>*</span>
        </p>
        <input type="text" v-model="targetAmount" />
      </div> -->
      <div class="flexL">
        <p class="bold">
          시작가
          <span>*</span>
        </p>
        <input style="text-align: right" type="text" v-model="startAmount" placeholder="숫자만 입력해 주세요." @keyup="removeChar" /><span class="noWidth">원</span>
      </div>
      <div class="flexL">
        <p class="bold">
          커트라인
          <span>*</span>
        </p>
        <input style="text-align: right" type="text" v-model="cutline" placeholder="숫자만 입력해 주세요." /><span class="noWidth">%</span>
      </div>
      <div class="flexL">
        <p class="bold">
          설문마감 유형
          <span>*</span>
        </p>
        <div class="deadline">
          <label class="endRadio">
            <input type="radio" v-model="limitType" value="PERIOD" checked="checked" />
            <span>설문일정</span>
          </label>
          <label class="endRadio">
            <input type="radio" v-model="limitType" value="COUNT" />
            <span>참여인원</span>
          </label>
        </div>
      </div>

      <div class="flexL">
        <p class="bold">
          설문시작일
          <span>*</span>
        </p>
        <div class="flexL">
          <el-date-picker v-model="startAt" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="Select date and time"> </el-date-picker>
        </div>
      </div>
      <div class="flexL" v-if="limitType == 'PERIOD'">
        <p class="bold">
          설문종료일
          <span>*</span>
        </p>
        <div class="flexL">
          <el-date-picker v-model="endAt" type="datetime" format="yyyy-MM-dd HH:mm" placeholder="Select date and time"> </el-date-picker>
        </div>
      </div>
      <div class="flexL" v-if="limitType == 'COUNT'">
        <p class="bold">
          참여인원
          <span>*</span>
        </p>
        <input style="text-align: right" type="number" v-model="userCount" /><span class="noWidth">명</span>
      </div>
      <div class="flexL">
        <p class="bold">
          당첨인원 설정
          <span>*</span>
        </p>
        <input style="text-align: right" type="text" v-model="winCount" /><span class="noWidth">명</span>
      </div>
      <div class="flexL">
        <p class="bold">
          쿠폰정보
          <span>*</span>
        </p>
        <input type="text" v-model="couponInfo" />
      </div>
      <div class="flexL">
        <p class="bold">
          지급 포인트
          <span>*</span>
        </p>
        <input style="text-align: right" type="text" v-model="point" /><span class="noWidth">P</span>
      </div>
      <div class="flexL">
        <p class="bold">
          썸네일 등록 <span>*</span>
          <br />
        </p>
        <div>
          <div class="flexB">
            <div class="filebox">
              <label for="thumbnail">찾아보기</label>
              <input type="file" ref="thumbnail" id="thumbnail" @change="sendFile('thumbnail')" />
            </div>
          </div>
          <div class="flex" v-if="thumbnail">
            <div class="imgWrap">
              <img :src="thumbnail" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(thumbnail, 'thumbnail')">
                <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="flexL">
        <p class="bold">상세 내용</p>
        <textarea v-model="contents" />
        <!-- <editor-content :editor="editor" /> -->
      </div>
      <div class="flexL">
        <p class="bold">
          이미지 등록 <span>*</span>
          <br />
        </p>
        <div>
          <div class="flexB">
            <div class="filebox">
              <label for="images">찾아보기</label>
              <input type="file" ref="images" id="images" @change="sendFile('images')" accept="image/*" multiple />
            </div>
          </div>
          <div class="flex" v-if="images.length > 0">
            <div v-for="(image, i) in images" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(image, 'images')">
                <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">인플루언서명</p>
        <input type="text" v-model="influencer" />
      </div>
      <div class="flexL">
        <p class="bold">유튜브 url</p>
        <input type="text" v-model="youtubUrl" placeholder="유튜브에서 공유 / 퍼가기에 iframe 소스를 입력해 주세요." />
      </div>
      <div class="flexL">
        <p class="bold">
          인플루언서 썸네일 등록
          <br />
        </p>
        <div>
          <div class="flexB">
            <div class="filebox">
              <label for="youtubeThumbnail">찾아보기</label>
              <input type="file" ref="youtubeThumbnail" id="youtubeThumbnail" @change="sendFile('youtubeThumbnail')" />
            </div>
          </div>
          <div class="flex" v-if="youtubeThumbnail.length > 0">
            <div v-for="(image, i) in youtubeThumbnail" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(image, 'youtubeThumbnail')">
                <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flexL">
        <p class="bold">인플루언서 내용</p>
        <textarea v-model="influencerContent" />
        <!-- <editor-content :editor="editor" /> -->
      </div>
      <div class="flexL" v-if="status == 'FINISH'">
        <p class="bold">
          결과 등록 <span>*</span>
          <br />
        </p>
        <div>
          <div class="flexB">
            <div class="filebox">
              <input type="text" class="upload-name" v-model="filename" disabled="disabled" placeholder="파일선택" />
              <label for="resultImages">찾아보기</label>
              <input type="file" ref="resultImages" id="resultImages" @change="sendFile('resultImages')" />
            </div>
          </div>
          <div class="flex" v-if="resultImages && resultImages.length > 0">
            <div v-for="(image, i) in resultImages" :key="i" class="imgWrap">
              <img :src="image" alt="이미지" id="img" />
              <a class="cancel" @click="handleDeleteFile(image, 'resultImages')">
                <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
              </a>
            </div>
          </div>
          <!-- <p>첫번째 이미지는 썸네일로 등록 됩니다.</p> -->
        </div>
      </div>
      <div class="buttonWrap">
        <button v-if="editMode" class="basic" @click="submit">수정</button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
        <button v-else class="basic" @click="submit">등록</button>
        <button class="right line" @click="handleMove">목록</button>
      </div>
    </div>
  </div>
</template>

<script>
import { createPost, updatePostDetails, removePost } from "@/api/admin";
import { fetchPostDetail } from "@/api/post";
import { uploadFile, deletePostFile, deleteFile } from "@/api/file";
import moment from "moment";
import { ko } from "vuejs-datepicker/dist/locale";
export default {
  name: "RegisterPost",

  data() {
    return {
      ko: ko,
      moment: moment,
      total: 0,
      currentPage: 1,
      editMode: false,
      startAt: "",
      endAt: "",
      tel: "",
      productName: "",
      provider: "",
      title: "",
      contents: "",
      category: "",
      startAmount: "",
      thumbnail: "",
      cutline: "",
      limitType: "PERIOD",
      userCount: 0,
      winCount: 0,
      couponInfo: "",
      point: "",
      influencer: "",
      youtubUrl: "",
      influencerContent: "",
      youtubeThumbnail: [],
      resultImages: [],
      images: [],
      surveyers: [],
      filename: "",
      postId: "",
      file: "",
      status: "",
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 2);
  },
  mounted() {
    if (this.$route.query.id) {
      this.postId = this.$route.query.id;
      this.editMode = true;
      this.getPost();
    }
  },
  methods: {
    inputNumberFormat(obj) {
      this.startAmount = this.comma(this.uncomma(obj));
    },

    comma(str) {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    },

    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else {
        this.startAmount = this.comma(this.uncomma(event.target.value.replace(/[^0-9]/g, "")));
      }
    },

    getPost() {
      fetchPostDetail(this.postId).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.title = res.data.data.title;
          this.limitType = res.data.data.limitType;
          this.thumbnail = res.data.data.thumbnail;
          this.company = res.data.data.company;
          this.provider = res.data.data.provider;
          this.productName = res.data.data.productName;
          this.cutline = res.data.data.cutline;
          this.tel = res.data.data.tel;
          this.targetAmount = res.data.data.targetAmount;
          this.startAt = res.data.data.startAt;
          this.endAt = res.data.data.endAt;
          this.contents = res.data.data.contents;
          this.images = res.data.data.images;
          this.couponInfo = res.data.data.couponInfo;
          this.point = res.data.data.point;
          this.influencer = res.data.data.influencer;
          this.youtubUrl = res.data.data.youtubUrl;
          this.youtubeThumbnail = res.data.data.youtubeThumbnail;
          this.influencerContent = res.data.data.influencerContent;
          this.startAmount = res.data.data.startAmount;
          this.avgAmount = res.data.data.avgAmount;
          this.userCount = res.data.data.userCount;
          this.winCount = res.data.data.winCount;
          this.author = res.data.data.author;
          this.surveyers = res.data.data.surveyers;
          this.status = res.data.data.status;
          this.resultImages = res.data.data.resultImages;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPost();
    },

    handleMove() {
      this.$router.push("/admin/posts");
    },
    deletePost() {},
    sendFile(type) {
      if (type == "thumbnail") {
        if (this.thumbnail) {
          return alert("썸네일 삭제 후 업로드 해주세요.");
        }
        this.file = this.$refs.thumbnail.files[0];
      } else if (type == "images") {
        if (this.images.length > 5) {
          return alert("이미지 등록은 5개까지 가능 합니다.");
        }
        this.file = this.$refs.images.files[0];
      } else if (type == "youtubeThumbnail") {
        this.file = this.$refs.youtubeThumbnail.files[0];
      } else if (type == "resultImages") {
        this.file = this.$refs.resultImages.files[0];
      }
      if (this.file == undefined) {
        return;
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = res.data.url;
            } else if (type == "images") {
              this.images.push(res.data.url);
            } else if (type == "youtubeThumbnail") {
              this.youtubeThumbnail.push(res.data.url);
            } else if (type == "resultImages") {
              this.resultImages.push(res.data.url);
            }
            this.file = "";
          } else {
            return alert(res.data.message);
          }
        } else {
          return alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.");
        }
      });
    },
    submit() {
      if (this.category == "") {
        return alert("카테고리를 선택해 주세요.");
      } else if (this.productName == "") {
        return alert("제품명을 입력해 주세요.");
      } else if (this.title == "") {
        return alert("제목을 입력해 주세요.");
      }
      // else if (this.provider == "") {
      //   return alert("설문주최를 입력해 주세요.");
      // }
      else if (this.startAmount == "") {
        return alert("시작가 입력해 주세요.");
      } else if (this.cutline == "") {
        return alert("커트라인을 입력해 주세요.");
        //  else if (this.limitType == "") {
        //   return alert("설문마감 유형을 선택해 주세요.");
        // }
      } else if (this.startAt == "") {
        return alert("설문시작일을 입력해 주세요.");
      } else if (!this.editMode && this.startAt < new Date()) {
        return alert("설문 시작일은 현재 시간 이후로 설정해 주세요");
      } else if (this.limitType == "PERIOD" && this.endAt == "") {
        return alert("설문종료일을 입력해 주세요.");
      } else if (this.limitType == "PERIOD" && this.endAt < new Date()) {
        return alert("설문 종료일을 오늘 이후로 설정해 주세요.");
      } else if (this.limitType == "PERIOD" && this.endAt < this.startAt) {
        return alert("설문 종료일은 설문 시작일 이후로 설정해 주세요.");
      } else if (this.limitType == "COUNT" && this.userCount == 0) {
        return alert("참여인원을 입력해 주세요.");
      } else if (this.winCount == null) {
        return alert("당첨인원을 입력해 주세요.");
      } else if (this.couponInfo == "") {
        return alert("쿠폰정보를 입력해 주세요.");
      } else if (this.point == "") {
        return alert("지급 포인트를 입력해 주세요.");
      } else if (this.thumbnail == "") {
        return alert("썸네일을 등록해 주세요.");
      } else if (this.images == "") {
        return alert("이미지를 등록해 주세요.");
      }
      // let formdata = new FormData();
      // formdata.append("category", this.category);
      // formdata.append("productName", this.productName);
      // formdata.append("title", this.title);
      // formdata.append("provider", this.provider);
      // formdata.append("startAmount", this.startAmount);
      // formdata.append("cutline", this.cutline);
      // formdata.append("limitType", this.limitType);
      // formdata.append("startAt", this.startAt);
      // formdata.append("endAt", this.endAt);
      // formdata.append("userCount", this.userCount);
      // formdata.append("winCount", this.winCount);
      // formdata.append("couponInfo", this.couponInfo);
      // formdata.append("point", this.point);
      // formdata.append("contents", this.contents);
      // formdata.append("influencer", this.influencer);
      // formdata.append("youtubUrl", this.youtubUrl);
      // formdata.append("influencerContent", this.influencerContent);
      // formdata.append("thumbnail", this.thumbnail);
      // formdata.append("images", this.images);
      // formdata.append("youtubeThumbnail", this.youtubeThumbnail);

      let data = {
        postId: this.postId,
        category: this.category,
        productName: this.productName,
        title: this.title,
        provider: this.provider,
        startAmount: this.uncomma(this.startAmount),
        cutline: this.cutline,
        limitType: this.limitType,
        startAt: this.startAt,
        endAt: this.limitType == "PERIOD" ? this.endAt : "",
        userCount: this.limitType == "COUNT" ? this.userCount : "",
        winCount: this.winCount,
        couponInfo: this.couponInfo,
        point: this.point,
        contents: this.contents,
        influencer: this.influencer,
        youtubUrl: this.youtubUrl,
        influencerContent: this.influencerContent,
        thumbnail: this.thumbnail,
        images: this.images,
        youtubeThumbnail: this.youtubeThumbnail,
        resultImages: this.resultImages,
      };
      if (this.editMode) {
        updatePostDetails(data).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "설문 정보가 수정 되었습니다.",
              type: "success",
            });
            this.$router.push("/admin/posts");
          } else {
            alert(res.data.message);
          }
        });
      } else {
        createPost(data).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "설문이 등록 되었습니다.",
              type: "success",
            });
            this.$router.push("/admin/posts");
            // let postId = res.data.data._id;
            // this.sendFile(postId);
          } else {
            alert(res.data.message);
          }
        });
      }
    },
    handleDeleteFile(url, type) {
      let result = confirm("이미지를 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      if (this.postId) {
        deletePostFile(this.postId, url, type).then((res) => {
          if (res.data.status == 200) {
            if (type == "images") {
              this.images.splice(this.images.indexOf(url), 1);
            } else if (type == "youtubeThumbnail") {
              this.youtubeThumbnail.splice(this.youtubeThumbnail.indexOf(url), 1);
            } else if (type == "thumbnail") {
              this.thumbnail = "";
            } else if (type == "resultImages") {
              this.resultImages.splice(this.resultImages.indexOf(url), 1);
            }
          } else {
            alert(res.data.message);
          }
        });
      } else {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            if (type == "images") {
              this.images.splice(this.images.indexOf(url), 1);
            } else if (type == "youtubeThumbnail") {
              this.youtubeThumbnail.splice(this.youtubeThumbnail.indexOf(url), 1);
            } else if (type == "thumbnail") {
              this.thumbnail = "";
            } else if (type == "resultImages") {
              this.resultImages.splice(this.resultImages.indexOf(url), 1);
            }
          } else {
            alert(res.data.message);
          }
        });
      }
    },
    handleDelete() {
      let result = confirm("설문을 삭제 하시겠습니까?");
      if (result == false) {
        return;
      }
      removePost(this.postId).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "설문이 삭제 되었습니다.",
            type: "success",
          });
          this.$router.push("/admin/posts");
        } else {
          alert(res.data.message);
        }
      });
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
  },
};
</script>
